@import "../App/Variables.scss";

.choose-label {
  color: $gold;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  .blinker {
    width: 10px;
    height: 16px;
    background-color: $gold;
    display: inline-block;
    margin-bottom: -1px;
    animation: blink 0.8s ease-in-out 0s infinite;
  }
}
