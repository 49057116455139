@import "../App/Variables.scss";
@import "../App/Mixins.scss";

#menu {
  background-color: #000000;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: scroll;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  transition: visibility 0s 0.4s, transform 0.4s;
  visibility: hidden;
  width: 100%;
  max-width: 100%;
  z-index: 650;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-bottom: 100px;
  .menu-close {
    position: absolute;
    top: 15px;
    left: 15px;
    color: #fff;
    font-size: 0;
    cursor: pointer;
    background-image: url("../../assets/close-large.svg");
    width: 10%;
    height: 10%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
  }

  .menu-content {
    height: 100vh;
    overflow-y: scroll;
    padding: 40px 0;
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    a {
      color: #fff;
      text-decoration: none;
      display: block;
    }
    li {
      position: relative;
      .count {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-190px);
        color: #fff;
        opacity: 0.6;
        font-size: 12px;
      }
    }
    &.stories {
      .story {
        height: 200px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include respond-below($tab) {
          max-height: 20vh;
        }
        .face {
          height: 70%;
          width: 100%;
          object-fit: contain;
          display: block;
          opacity: 0.3;
        }
        .overlay {
          position: absolute;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          width: 250px;
          border-bottom: 1px #333 solid;
        }
      }
      a {
        color: #fff;
        text-decoration: none;
        display: block;
        &:hover {
          color: #956a15;
        }
        &.lead-link {
          margin-bottom: 20px;
          font-size: 50px;
          font-family: $canela;
          text-align: center;
          @include respond-below($tab) {
            font-size: 42px;
          }
        }
      }
      .sub-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        a {
          text-transform: uppercase;
          font-size: 18px;
        }
      }
    }
  }

  .supporting {
    margin-top: 40px;
    li {
      margin: 20px 0;
      text-transform: uppercase;
      a {
        color: #999;
        &:hover {
          color: #fff;
        }
      }
    }
  }

  &.open {
    transform: translateX(0);
    transition: transform 0.4s;
    visibility: visible;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
