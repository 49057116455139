@import "../App/Variables.scss";
@import "../App/Mixins.scss";

.article-main .article-wrap .block-container.image-block {
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  @include respond-below($tab) {
    width: 90%;
  }
}

.image-columns,
.image-full {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 30px;
  @include respond-below($tab) {
    margin-bottom: 20px;
    width: 100%;
  }
  .img-wrap {
    width: 100%;
    margin-top: 30px;
    position: relative;
    display: flex;
    overflow: hidden;
    background: #fafafa;
    @include respond-below($tab) {
      margin-top: 20px;
    }
    &:first-child {
      margin-top: 0;
    }
    &.square {
      padding-top: 69%;
    }
    &.tall {
      padding-top: 138%;
    }
    &.wide {
      padding-top: 50%;
    }
    .container {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    img {
      width: 100%;
      animation: image-reveal 0.2s ease-in;
      // opacity: 0;
    }
  }
}
@keyframes image-reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.image-columns {
  .col {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    @include respond-below($tab) {
      width: calc(50% - 10px);
    }
    &.align-end {
      justify-content: flex-end;
    }
    &.start-space {
      padding-top: 30%;
    }
  }
}
