@import "../App/Variables.scss";
@import "../App/Mixins.scss";
@import "../App/Article.scss";

.game-on-sequence {
  .mute-toggle {
    position: fixed;
    left: 2%;
    bottom: 2%;
    z-index: 100;
    opacity: 0.6;
  }
  .hero-wrap {
    height: 100vh;
    position: relative;
    .hero {
      height: 100vh;
      @include respond-below($tab) {
        height: 60vh;
      }
    }
    .load-wrap {
      position: absolute;
      bottom: 5%;
      left: 10%;
      width: 40%;
      height: 20px;
      @include respond-below($tab) {
        bottom: 25vh;
      }
      .label {
        font-size: 12px;
        text-transform: uppercase;
        color: #666;
        margin-bottom: 10px;
      }
      .load-bar {
        background-color: #666;
        .bar {
          height: 2px;
          background-color: #ffffff;
          width: 0;
        }
      }
    }
    .play-prompt {
      position: absolute;
      bottom: calc(12% + 20px);
      left: 10%;
      width: 40%;
      color: #fff;
      cursor: pointer;
      .btn {
        @include btn();
        display: inline;
        border-color: #ddcb99;
        border-bottom-color: #ddcb99;
        color: #ddcb99;
        &:hover {
          color: #fff;
          border-color: #ffffff;
          border-bottom-color: #ffffff;
          color: #ffffff;
        }
      }
    }
  }
  .main-wrap {
    background-color: #000000;
    color: white;
    display: flex;
    flex-direction: column;
    .sequence-wrap {
      position: sticky;
      top: 0;
      height: 100vh;
      width: 100vw;

      // Opacity approach
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        object-fit: contain;
        // To tell the browser to treat as a layer.
        will-change: transform;
        transform: translateZ(0);
        opacity: 0;
      }
    }
  }

  .slide-group {
    margin-left: 10%;
    &.humphrey {
      margin-top: -100vh;
      margin-bottom: 50vh;
    }
    &.controller {
      padding-bottom: 200vh;
      .scrollmagic-pin-spacer:first-child {
        .paragraph:first-child {
          padding-top: 30vh;
        }
      }
    }

    .scrollmagic-pin-spacer {
      width: 50% !important;
      @include respond-below($tab) {
        width: 80% !important;
      }
    }
    .slide-wrap {
      width: 50%;
      @include respond-below($tab) {
        width: 80%;
      }
      .slide {
        padding-top: 40px;
        .paragraph {
          font-size: 32px;
          margin-bottom: 1em;
          @include respond-below($tab) {
            font-size: 22px;
          }
        }
      }
    }
    .quote-wrap {
      width: 60%;
      position: relative;
      z-index: 2;
      @include respond-below($tab) {
        width: 80%;
      }
      .quote-content {
        padding-top: 25vh;
        // opacity: 0;
        .quotes {
          font-family: $canela;
          font-size: 48px;
          @include respond-below($tab) {
            font-size: 22px;
            line-height: 1.2em;
          }
          audio {
            display: none;
          }
        }
      }
      .replay-btn {
        font-family: $sans;
        font-size: 15px;
        text-transform: uppercase;
        margin-top: 15px;
        color: #fff;
        cursor: pointer;
        opacity: 0.4;
        transition: opacity 0.4s ease-out;
        &:hover {
          opacity: 1;
        }
        #replay {
          height: 15px;
          margin-right: 4px;
        }
      }
    }
  }
  .article-main {
    will-change: transform;
    transform: translateZ(0);

    .article-wrap {
      background: #fff;
      width: 100%;
      font-weight: normal;
      color: #000;

      // .text-block {
      //   width: 65%;
      //   font-size: 18px;
      //   line-height: 1.5em;
      //   font-size: 20px;
      //   blockquote {
      //     margin: 2.5em 0;
      //     p {
      //       font-weight: bold;
      //       font-size: 28px;
      //       line-height: 1.3em;
      //     }
      //   }
      //   @include phone {
      //     width: 90%;
      //     font-size: 16px;
      //   }
      // }
    }
  }

  &.src-approach {
    .main-wrap {
      background-color: #000000;
      color: white;
      display: flex;
      flex-direction: column;
      .sequence-wrap {
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 100vw;
          height: 100vh;
          object-fit: contain;
          // To tell the browser to treat as a layer.
          will-change: transform;
          transform: translateZ(0);
          opacity: 1;
        }
      }
    }
  }
}

.app.gameOn {
  @include content-loader();
}
