@media screen and (max-width: 1199px) {
  .timeline-card {
    &__content {
      padding: 0 30px;
    }
  }
}
@media screen and (min-width: 768px) {
  .timeline-slide {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .timeline-container {
    display: none;
  }
  .timeline-card {
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;
    }
    &__header {
      margin: 0 0 10px;
      padding-bottom: 10px;
      position: relative;
      opacity: 0;
      transition: all 0.1s;
      &::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #000000;
        border-radius: 50%;
        left: 50%;
        bottom: -1px;
        z-index: 3;
        transform: translateX(-50%);
      }
    }
    &__content {
      max-width: 100%;
      margin: 0 auto;
      text-align: center;
      padding: 0 30px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &__date {
      margin-top: 0;
      margin-bottom: 10px;
    }
    // &::after{
    //     position: absolute;
    //     content: '';
    //     width: 100%;
    //     height: 1px;
    //     background-color: #B5B5A0;
    //     top: calc(50% + 5px);
    //     left: 0;
    // }
  }
  .timeline-slide {
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    .slick-list {
      z-index: 2;
    }
    .slick-track {
      .slick-slide {
        position: relative;
        height: 200px;
        > div {
          height: 100%;
        }
        .timeline-card {
          height: 100%;
          &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // background-color: #e6e6df;
            // position: relative;
            z-index: 3;
            transition: all 0.1s;
          }
        }
      }
    }
  }
  .timeline-arrow {
    width: 60px;
    height: 60px;
    bottom: -50px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    > div {
      margin-top: -9px;
    }
    &__btn {
      border: solid #b5b5a0;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 6px;
      background-color: transparent;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &--prev {
        transform: rotate(135deg);
      }
      &--next {
        transform: rotate(-45deg);
      }
    }
  }
}
.timeline-container {
  position: relative;
  margin-top: 150px;
  .timeline {
    overflow-x: hidden;
  }
  svg {
    width: 100%;
    height: 100%;
    cursor: grab;
    .timeline-circle {
      transition: all 1s ease-out;
    }
  }
  .wrapper-timeline-svg {
    width: 100%;
    left: 50%;
    position: relative;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 200rem;
    &.hide-cir {
      .timeline-circle {
        opacity: 0;
      }
    }
  }
  .timeline-circle {
    -webkit-user-select: none;
    -moz-user-select: moz-none;
    -ms-user-select: none;
    user-select: none;
    &.hide {
      opacity: 0;
    }
  }
  .wrapper-timeline {
    width: 21rem;
    position: absolute;
    left: 50%;
    top: -100px;
    transform: translateX(-50%);
    pointer-events: none;
    // background-color: #e6e6df;
    .timeline-item {
      width: 100%;
      min-height: 200px;
      display: inline-block;
      background-color: #e6e6df;
      position: absolute;
      visibility: hidden;
      padding: 10px 30px;
      box-sizing: border-box;
      opacity: 0;
      text-align: center;
      //   -webkit-transform: translateY(-10rem);
      //   transform: translateY(-10rem);
      -webkit-transform: scale(0);
      transform: scale(0);
      transition: opacity 0.3s, visibility 0s, -webkit-transform 0s 0.3s;
      transition: opacity 0.3s, transform 0s 0.3s, visibility 0s;
      transition: opacity 0.3s, transform 0s 0.3s, visibility 0s,
        -webkit-transform 0s 0.3s;
      display: flex;
      align-items: center;
    }
    .timeline-item[data-visible="true"] {
      position: relative;
      visibility: visible;
      opacity: 1;
      //   transform: translateY(0);
      -webkit-transform: scale(1);
      transform: scale(1);
      transition: opacity 0.3s 0.5s,
        -webkit-transform 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0.55s;
      transition: opacity 0.3s 0.5s,
        transform 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0.55s;
      transition: opacity 0.3s 0.5s,
        transform 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0.55s,
        -webkit-transform 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0.55s;
    }
  }
}
