@import "../App/Variables.scss";
@import "../App/Mixins.scss";
@import "../App/Base.scss";

#the-article {
  // margin-top: -40vh;
  width: 100%;
}

.article-main {
  width: 100%;
  background: #fff;
  z-index: 2;
  position: relative;
  will-change: transform;
  transform: translateZ(0);

  .article-wrap {
    padding: 10% 0 100px 0;
    @include respond-below($tab) {
      padding: 30px 0 20px 0;
    }
    .title {
      margin: 0 15%;
      border-top: 1px solid #000;
      @include respond-below($tab) {
        margin: 0 10%;
      }
      h2 {
        font-family: $canela;
        font-size: 70px;
        font-weight: normal;
        color: #000000;
        letter-spacing: -0.64px;
        line-height: 82px;
        margin: 40px 0;
        span {
          display: block;
        }
        @include respond-below($tab) {
          font-size: 40px;
          line-height: 48px;
          margin: 20px 0;
        }
      }
      .read-duration {
        font-size: 20px;
        color: #826a6a;
        letter-spacing: -0.1px;
        line-height: 31px;
      }
      .intro {
        width: 65%;
        font-weight: bold;
        font-size: 28px;
        line-height: 1.3em;
        margin-top: 30px;
        @include respond-below($tab) {
          font-size: 18px;
          width: 100%;
        }
      }
    }

    .section-heading {
      margin: 0 15%;
      border-top: 1px solid #000;
      @include respond-below($tab) {
        margin: 0 10%;
        border-top: 0px none transparent;
      }

      h2 {
        font-family: $canela;
        font-size: 70px;
        font-weight: normal;
        color: #000000;
        letter-spacing: -0.64px;
        line-height: 82px;
        margin: 40px 0;
        span {
          display: block;
        }
        @include respond-below($tab) {
          font-size: 40px;
          line-height: 48px;
          margin: 20px 0;
        }
      }
    }

    .block-container + .section-heading {
      margin-top: 200px;
      @include respond-below($tab) {
        margin-top: 200px;
      }
    }

    .quote-wrap {
      margin: 130px 0;
      width: 100vw;
      @include respond-below($tab) {
        margin: 30px 0;
      }
      .inner {
        width: 70%;
        max-width: 70%;
        margin: 0 15%;
        display: flex;
        flex-direction: row;
        @include respond-below($tab) {
          flex-direction: column;
          width: 80%;
          margin: 0 10%;
        }
      }

      .video-wrap {
        overflow: hidden;
        width: 35%;
        position: relative;
        video.video-quote {
          opacity: 0.2;
          width: 100%;
          min-height: 50vh;
          height: 100%;
          object-fit: cover;
          object-position: left;
          transition: all 0.6s ease-out;
          pointer-events: none;
          filter: blur(10px) grayscale(100%);
          transform: scale(1.2);
          position: relative;
        }
        &.current {
          video {
            opacity: 0.2;
          }
        }
        &.active {
          z-index: 5;
          video {
            opacity: 1;
            transition: all 0.6s ease-out;
            filter: blur(0) grayscale(100%);
            transform: scale(1);
          }
        }
        @include respond-below($tab) {
          width: 100%;
          video.video-quote {
          }
        }
        .muteToggle {
          position: absolute;
          top: 10px;
          right: 10px;
          background: #fff;
          padding: 4px;
          cursor: pointer;
        }
      }

      .quotes {
        width: 60%;
        height: auto;
        margin-left: 5%;
        font-family: Canela;
        @include respond-below($tab) {
          width: 100%;
          margin-left: 0;
          margin-top: 15px;
        }

        .quote-text {
          color: rgb(201, 102, 102);
          font-size: 30px;
          -webkit-font-smoothing: auto;
          background-blend-mode: color;
          line-height: 1.4em;
          margin-bottom: 30px;
          @include respond-below($tab) {
            font-size: 20px;
            margin-bottom: 0;
          }
        }
      }
    }

    .block-container {
      display: flex;
      flex-direction: row;
      width: 70%;
      margin: 0 15%;
      justify-content: space-between;
      @include respond-below($tab) {
        flex-direction: column;
        width: 80%;
        margin: 0 10%;
        justify-content: space-between;
      }
      &.intro,
      &.minister,
      &.outro,
      &.community {
        .text-block {
          font-family: $canela;
          font-size: 26px;
          line-height: 1.4em;
          &::first-letter {
            font-size: 2.8em;
            margin-top: 0.15em;
            float: left;
            line-height: 0.8em;
            margin-right: 10px;
            margin-left: -13px;
          }
          @include respond-below($tab) {
            font-size: 20px;
            line-height: 1.4em;
            &::first-letter {
              font-size: 2.8em;
              margin-top: 0.15em;
              float: left;
              line-height: 0.8em;
              margin-right: 10px;
              margin-left: -13px;
            }
          }
        }
      }

      &.outro,
      &.community {
        margin-top: 150px;
      }

      .text-block {
        width: 65%;
        font-size: 18px;
        line-height: 1.5em;
        font-size: 20px;
        blockquote {
          margin: 2.5em 0;
          p {
            font-weight: bold;
            font-size: 28px;
            line-height: 1.3em;
          }
        }
        @include respond-below($tab) {
          width: 100%;
          font-size: 16px;
        }
      }
      &.youtube-video {
        margin-top: 60px;
        margin-bottom: 60px;
        .video-wrap {
          margin: 0 auto;
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden;
          width: 100%;
          max-width: 100%;
          iframe,
          object,
          embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .ytp-expand-pause-overlay {
            display: none;
          }
        }
      }
    }
  }
}

.game-prompt-spacer {
  height: 200vh;
  background-color: #f5f5f5;
  @include respond-below($tab) {
    // display: none;
    height: auto;
    background-color: #fff;
    overflow: hidden;
    margin-top: -5px;
    padding: 0 0 80px 0px;
  }
}
.next-article {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #000;
  padding-left: 5%;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 30;
  .story-link {
    width: 25vw;
    height: 25vw;
    color: #fff;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .face {
      img {
        height: 25vw;
        width: auto;
        filter: grayscale(100%);
        opacity: 0.5;
        transition: all 0.3s 0s ease-out;
      }
    }
    &:hover {
      .face {
        img {
          filter: grayscale(0%);
          transition: all 0.3s 0s ease-out;
        }
      }
    }
  }
}
