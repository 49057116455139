@keyframes frame-viewer-spin {
  100% {
    transform: rotate(360deg);
  }
}

.frame-viewer-spinner {
  $size: 26px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: none;
  width: $size;
  height: $size;

  &.isVisible {
    display: block;
  }

  &::before {
    content: '';

    position: relative;

    display: block;
    width: $size;
    height: $size;

    transform-origin: center;

    background-image: url('./assets/spinner.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    animation: frame-viewer-spin 1s linear infinite;
  }
}
