@import "../App/Variables.scss";
@import "../App/Mixins.scss";

#app.game-on-page {
  #nav-bar {
    will-change: transform;
    transform: translateZ(0);
  }
}

.gameOnCanvas {
  .mute-toggle {
    position: fixed;
    left: 2%;
    bottom: 2%;
    z-index: 100;
    opacity: 0.6;
    will-change: transform;
    transform: translateZ(0);
  }
  .hero-wrap {
    height: 100vh;
    position: relative;
    z-index: 30;
    .hero {
      height: 100vh;
      @include respond-below($tab) {
        height: 50vh;
      }
    }
    .load-wrap {
      position: absolute;
      bottom: 8%;
      left: 7%;
      width: 40%;
      height: 20px;

      box-sizing: border-box;
      padding-bottom: 24px;

      // display: none;
      @include respond-below($tab) {
        bottom: 20vh;
        left: 10%;
        width: 80%;

        padding-bottom: 0;
      }
      .label {
        font-size: 12px;
        text-transform: uppercase;
        color: #666;
        margin-bottom: 10px;
      }
      .load-bar {
        background-color: #666;
        .bar {
          height: 2px;
          background-color: #ffffff;
          width: 0;
        }
      }
      .wifi-msg {
        color: #fff;
        position: relative;
        font-size: 14px;
        text-transform: uppercase;
        opacity: 0.7;
        margin-top: 10px;
      }
    }
    .processing {
      position: absolute;
      bottom: 5%;
      left: 7%;
      width: 40%;
      height: 20px;
      opacity: 0;
      font-size: 12px;
      text-transform: uppercase;
      color: #666;
      @include respond-below($tab) {
        bottom: 25vh;
        left: 10%;
      }
    }
    .play-prompt {
      position: absolute;
      bottom: calc(15% + 20px);
      left: 7%;
      width: 40%;
      color: #fff;
      cursor: pointer;
      @include respond-below($tab) {
        width: 100%;
        left: 10%;
        bottom: calc(15% + 20px);
      }
      .btn {
        @include btn();
        display: inline;
        border-color: #ddcb99;
        border-bottom-color: #ddcb99;
        color: #ddcb99;
        &:hover {
          color: #fff;
          border-color: #ffffff;
          border-bottom-color: #ffffff;
          color: #ffffff;
        }
      }
    }
  }

  .article-main {
    z-index: 20;
  }
  .canvas-wrap {
    position: sticky;
    z-index: 1;
    top: 0;
  }
  canvas {
    // position: sticky;
    // top: 0;
    display: block;
  }

  .image-container {
    position: absolute;
    top: 0;
    right: 0;

    display: block;
    // width: 100vh * 1920 / 1088;
    width: 100%;
    height: 100vh;

    @media (max-width: 700px) {
      transform: scale(0.65);
      transform-origin: bottom right;
    }

    > img {
      position: absolute;
      left: 0;
      top: 0;

      display: block;
      width: 100%;
      height: 100%;

      user-select: none;
      pointer-events: none;
      object-fit: contain;
    }
  }

  @at-root {
    [data-with-mobile-frame-images] .image-container {
      $height-scale: 1.5;

      top: -50vh;
      height: 100vh * $height-scale;
      width: 100vh * 1008 / 1600 * $height-scale;

      @media (max-width: 700px) {
        transform: scale(0.675);
        transform-origin: bottom right;
      }
    }
  }

  .canvas-shadow {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: inset 42px 0px 39px 35px rgba(0, 0, 0, 1);
    -moz-box-shadow: inset 42px 0px 39px 35px rgba(0, 0, 0, 1);
    box-shadow: inset 42px 0px 39px 35px rgba(0, 0, 0, 1);
  }
  .scroll-progress {
    position: sticky;
    top: 0;
    left: 0px;
    width: 100%;
    overflow: hidden;
    background-color: #222;
    opacity: 0;
    z-index: 2;
    &.show {
      opacity: 1;
    }
    .bar {
      height: 2px;
      background-color: #fff;
      transition: all 0.2s ease-out;
    }
  }
  .part-one-wrap {
    z-index: 15;
    position: relative;
  }
  .slides {
    color: #fff;

    .quote {
      opacity: 0;
      position: fixed;
      z-index: 10;
      top: 20vh;
      left: 10vw;
      padding-top: 40px;
      width: 50%;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.75);
      @include respond-below($tab) {
        width: 100%;
        &:after {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.8);
          filter: blur(10px);
        }
      }
      .quotes {
        font-family: $canela;
        font-size: 36px;
        @include respond-below($tab) {
          font-size: 20px;
          line-height: 1.25em;
          width: 75%;
        }
        audio {
          display: none;
        }
      }
      .replay-btn {
        display: none;
        font-family: $sans;
        font-size: 15px;
        text-transform: uppercase;
        margin-top: 15px;
        color: #fff;
        cursor: pointer;
        opacity: 0.4;
        transition: opacity 0.4s ease-out;
        &:hover {
          opacity: 1;
        }
        #replay {
          height: 15px;
          margin-right: 4px;
        }
      }
    }

    .slide {
      opacity: 0;
      position: fixed;
      z-index: 10;
      top: 20vh;
      left: 10vw;
      padding-top: 40px;
      width: 50%;
      // background: rgba(0, 0, 0, 0.5);
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.75);
      @include respond-below($tab) {
        width: 60%;
        top: 40px;
      }
      p {
        font-size: 32px;
        margin-bottom: 1em;
        @include respond-below($tab) {
          font-size: 22px;
        }
        &.small {
          width: 80%;
          font-size: 22px;
          @include respond-below($tab) {
            font-size: 16px;
          }
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        filter: blur(10px);
      }
      &#controllerExplode {
        padding-bottom: 30px;
        width: 40%;
        p.small {
          width: 100%;
        }
      }
    }
  }
}
