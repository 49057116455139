@import "../App/Mixins.scss";

.day-in-the-life {
  --mobile-margin-top: 20%;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  .video-wrap {
    position: relative;
    video {
      height: 85vh;
      // width: auto;
      width: calc(85vh * 1.323);
      object-position: center;
      object-fit: contain;
      opacity: 0;
      cursor: pointer;
      display: block;
      &.loaded {
        opacity: 1;
      }
      @include respond-below($tab) {
        width: 100%;
        height: auto;
        margin-top: var(--mobile-margin-top);
      }
    }
    .prompt-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // transform: translate(-50%, -50%);
      opacity: 0;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.show {
        opacity: 1;
      }
    }
  }
  .controls-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .controls {
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      transition: opacity 3s 0s cubic-bezier(0.14, 1, 0.34, 1);
      &.false {
        opacity: 0;
        transition: opacity 0.6s 0s cubic-bezier(0.14, 1, 0.34, 1);
      }
      .control {
        padding: 15px;
        cursor: pointer;
      }
    }
    .game-links-wrap {
      padding: 0;
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      align-content: center;
      color: #fff;
      &.false {
        opacity: 0;
        transition: opacity 0.6s 0s cubic-bezier(0.14, 1, 0.34, 1);
      }
      .label {
        font-size: 12px;
        padding: 0 15px;
      }
      .game-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        div {
          padding: 5px 15px;
          cursor: pointer;
        }
      }
    }
  }
  #canvas-wrap {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    &.disabled {
      pointer-events: none;
    }
    @include respond-below($tab) {
      width: 100%;
      height: auto;
      top: 0;
      margin-top: var(--mobile-margin-top);
    }
  }
  #endplate {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    &.show {
      display: flex;
    }
    h2 {
      margin-bottom: 10px;
    }
    .continue-prompt {
      margin-top: 30px;
      a {
        color: #fff;
        font-weight: bold;
        font-size: 20px;
      }
    }
    .share-widgets {
      display: flex;
      flex-direction: row;
      justify-content: center;
      > div {
        margin: 0 15px 20px;
      }
    }
    @include respond-below($tab) {
      width: 100%;
      height: auto;
      top: 0;
      margin-top: var(--mobile-margin-top);
    }
  }
}

// .app.gameFace {
//   @include respond-below($tab) {
//     .nav-bar {
//       display: none;
//     }
//   }
// }
