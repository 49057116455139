@import "../App/Mixins.scss";
@import "../App/Base.scss";

.game-plan {
  .article-wrap {
    .left {
      background-color: #94adcb;
      .title {
        background: #94adcb;
      }
      .read-duration {
        color: #4b607c;
      }
    }
    .quotes {
      border-left-color: #4179bd;

      .quote-text {
        color: #b6cae1;
      }
    }
    #a44-quote-three {
      span {
        display: block;
        &:first-child {
          font-family: $sans;
        }
        &:not(:first-child):not(:last-child) {
          &:before {
            content: "•";
            display: inline-block;
            margin: 10px 10px 0 20px;
          }
        }
      }
    }
  }
}
