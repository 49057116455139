@import "../App/Variables.scss";
@import "../App/Mixins.scss";

.hero {
  line-height: 0;
  position: relative;
  width: 100vw;
  height: calc(100vh - 50px);
  // height: 90vh;
  // overflow: hidden;
  @include respond-below($tab) {
    height: auto;
  }

  .article-scroll-prompt {
    position: absolute;
    bottom: -26px;
    left: 0;
    width: 100%;
    text-align: center;
    color: $gold;
    font-family: $displaySansBold;
    font-size: 14px;
    z-index: 10;
    transition: all 0.3s ease-out;
    @include respond-below($tab) {
      display: none;
    }
    &.hide {
      opacity: 0;
      transform: translateY(50px);
    }
  }

  .face {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #000;
    overflow: hidden;
    @include respond-below($tab) {
      position: relative;
    }
    video {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: bottom;
    }
  }
  .hero-title-wrap {
    --title-size: 70px;
    --title-line: 1.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include respond-below($tab) {
      --title-size: 40px;
      position: relative;
      top: unset;
      left: unset;
      height: auto;
      justify-content: flex-start;
      margin-top: calc(var(--title-size) * -1.3);
    }
    .hero-head-text {
      margin-left: 7%;
      @include respond-below($tab) {
        margin-left: 10%;
      }
      .hero-title {
        color: #fff;
        font-size: var(--title-size);
        font-family: $canela;
        font-weight: 400;
        line-height: calc(var(--title-line) * 1em);
      }

      .hero-subtitle {
        .tagline-line {
          --subtitle-line: 50px;
          color: #fff;
          font-size: 50px;
          line-height: var(--subtitle-line);
          font-family: $canela;
          font-weight: 100;
          @include respond-below($tab) {
            --subtitle-line: 36px;
            font-size: 30px;
          }
        }
      }
    }

    .hero-intro {
      position: absolute;
      color: #fff;
      font-size: 20px;
      bottom: calc(5% + 70px);
      left: 7%;
      max-width: 400px;
      width: 70%;
      line-height: 1.4em;
      font-family: $displaySansBold;
      @include respond-below($tab) {
        bottom: calc(5% + 70px);
      }
    }
  }
  .scroll-prompt {
    position: absolute;
    bottom: 15%;
    right: 5%;
    color: #fff;
    transition: all 0.2s ease-out;

    &.hide {
      opacity: 0;
      transition: all 0.2s ease-out;
    }
    .label {
      transform: rotate(270deg);
      transform-origin: top right;
      text-transform: uppercase;
    }
  }
}
.game-face .hero-wrap,
.game-plan .hero-wrap {
  @include respond-below($tab) {
    .face {
      height: 300px;
    }
    .hero-title {
      top: 340px;
      left: 0;
      width: 100vw;
      height: auto;
      .tagline-line {
        left: 7%;
      }
      .hero-intro {
        position: relative;
        font-size: 14px;
        font-family: $displaySans;
        color: #b6b6b6;
        // margin-top: calc(
        //   10% + #{$lineheightMob} + #{$lineheightMob} + #{$lineheightMob}
        // );
      }
    }
    .game-tease {
      // display: none;
    }
  }
}

.hero-wrap {
  &.game-on {
    @include respond-below($tab) {
      height: 100vh;
      position: relative;
    }
    .hero {
      @include respond-below($tab) {
        height: 60vh;
      }
    }
  }
}
