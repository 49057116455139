@import "../App/Variables.scss";
@import "../App/Mixins.scss";

.landing {
  background-color: #000000;
  min-height: 100vh;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.9s 0.5s ease-out;
  @include respond-below($tab) {
    .nav-bar {
      padding-top: 35px;
    }
  }
  .mute-toggle {
    position: fixed;
    left: 2%;
    bottom: 2%;
    z-index: 50;
    opacity: 0.6;
  }
  .rnz-logo {
    position: fixed;
    right: 2%;
    top: 27px;
    z-index: 50;
    width: 90px;
    @include respond-below($tab) {
      width: 70px;
      top: 30px;
    }
    img {
      width: 100%;
    }
  }
  .nzoa-logo {
    position: fixed;
    right: 2%;
    bottom: 2%;
    z-index: 50;
    width: 100px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    @include respond-below($tab) {
      width: 70px;
      bottom: 1%;
    }
    img {
      width: 100%;
    }
  }

  .wrap {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-below($tab) {
      padding-bottom: 150px;
    }
  }
  .bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 2% 0;
    .links {
      margin-bottom: 15px;

      a {
        color: #666666;
        text-decoration: none;
        text-transform: uppercase;
        margin-left: 40px;
        font-weight: bold;
        transition: all 0.6s ease-out;

        &:first-child {
          margin-left: 0;
        }
        &:hover {
          color: #f1f1f1;
        }
      }
    }
    .accreditation {
      font-weight: 100;
      color: #666666;
    }
  }
  .heading {
    text-align: center;
    width: 100%;
    transition: all 0.9s 0.5s ease-out;
    h1 {
      @include respond-below($tab) {
        svg {
          width: 45%;
          height: 100%;
        }
      }
    }
    h2 {
      width: 50%;
      min-width: 300px;
      font-family: $canela;
      font-weight: 200;
      color: $gold;
      font-weight: normal;
      font-size: 34px;
      line-height: 1.3em;
      margin: 20px auto;
      @include respond-below($tab) {
        font-size: 24px;
        line-height: 1.2em;
        width: 90%;
      }
    }
  }
  .choose-label {
    color: #666666;
    margin-top: 30px;
    .blinker {
      background-color: #666666;
    }
  }
  .joint-fund-logo {
    margin: 250px auto 100px;
    img {
      width: 100%;
      max-width: 300px;
    }
  }
  &.dull {
    background-color: #1a1a1a;
    transition: all 0.6s 0s ease-in-out;
    .heading {
      opacity: 0.4;
      transition: all 0.6s 0s ease-in-out;
    }
  }
  &.move-out {
    .heading {
      opacity: 0;
      transform: translateY(-50px);
      transition: all 0.3s 0.2s ease-in-out;
    }
    .choose-label {
      opacity: 0;
      transform: translateY(150px);
      transition: all 0.3s 0s ease-in-out;
    }
    .joint-fund-logo {
      opacity: 0;
      transform: translateY(150px);
      transition: all 0.3s 0s ease-in-out;
    }
  }
  .characters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5%;
    width: 100%;
    margin-top: 30px;
    @include respond-below($tab) {
      flex-direction: column;
    }
    .character {
      width: 45%;
      height: 200px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      perspective: 600px;
      transition: opacity 0.9s 1s ease-out;
      &:hover {
        opacity: 1;
        transition: opacity 0.3s 0s ease-in-out;
      }
      &.dull {
        opacity: 0.2;
        transition: opacity 0.6s 0s ease-in-out;
        &:hover {
          opacity: 1;
          transition: opacity 0.3s 0s ease-in-out;
        }
      }
      &.move-out {
        opacity: 0;
        transform: translateY(100px);
        transition: all 0.6s 0s ease-in-out;
        @include respond-below($tab) {
          transform: translateX(-100px);
        }
      }
      @include respond-below($tab) {
        width: 90%;
        height: 200px;
        margin-bottom: 30px;
      }
      .content {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        padding: 5%;
        .title {
          font-family: $canela;
          font-size: 44px;
          font-weight: normal;
          opacity: 0;
          color: #ffffff;
          margin-bottom: 20px;
        }
        .subtitle {
          font-family: $canela;
          font-size: 28px;
          font-weight: 200;
          opacity: 0;
          width: 75%;
        }
        .start {
          position: absolute;
          right: 25px;
          bottom: 25px;
          opacity: 0;
        }
        .number {
          position: absolute;
          right: 25px;
          top: 25px;
          opacity: 0;
          transition: all 0.9s ease-out;
        }
      }
      &:hover {
        .number {
          opacity: 0.6;
        }
      }
      .face {
        height: 100%;
        transform: translateY(-15%);
        position: relative;
        img {
          height: 130%;
          width: auto;
          @include respond-below($tab) {
            // transform: translateX(-20%);
            height: 110%;
          }
        }
        .shadow {
          position: absolute;
          top: 0;
          left: 0;
          filter: brightness(0);
          opacity: 0;
        }
      }
      .bg {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: scale(0);
        background-color: #000;
        // &.gamePlan {
        //   background-color: #2c5789;
        // }
        // &.gameFace {
        //   background-color: #92114d;
        // }
        // &.gameOn {
        //   background-color: #616b56;
        // }
      }
      .screen {
        height: 100%;
        width: 0%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #666;
      }
      .mask {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #000000;
        opacity: 0;
        z-index: 100;
      }
      // &:hover {
      //   .content {
      //     opacity: 1;
      //   }
      //   .bg {
      //     opacity: 1;
      //   }
      //   .face {
      //     transform: translateY(-20%);
      //     img {
      //       height: 140%;
      //       &.shadow {
      //         opacity: 0.4;
      //       }
      //     }
      //   }
      // }
    }
  }
  #audio-prompt {
    --color: #666666;
    margin-top: 30px;
    text-transform: uppercase;
    cursor: pointer;
    color: var(--color);
    display: flex;
    align-items: flex-end;
    transition: color 0.3s ease-in-out;
    &:hover {
      --color: #ffffff;
    }

    .label {
      margin-left: 20px;
      line-height: 1em;
    }

    .icon-bars {
      $size: 20px;
      $speed: 1.6s;
      $bar-width: ceil($size / 5);
      $gutter-width: floor(($size - $bar-width * 3) / 2);

      position: relative;
      width: $size;
      height: $size;

      .bar {
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
        width: $bar-width;
        background-color: var(--color);
        transition: background-color 0.3s ease-in-out;
        animation: up-down $speed infinite;

        &:nth-child(2) {
          left: $bar-width + $gutter-width;
          animation-delay: -$speed / 3 * 2;
        }
        &:nth-child(3) {
          left: $bar-width * 2 + $gutter-width * 2;
          animation-delay: -$speed / 3;
        }
      }

      @keyframes up-down {
        from {
          top: $size;
        }
        50% {
          top: 0;
        }
        to {
          top: $size;
        }
      }
    }
  }
}
