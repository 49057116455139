@import "../App/Mixins.scss";
@import "../App/Base.scss";

.story-wrap-split {
  display: flex;
  flex-direction: column;
  align-items: center;

  .section {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-content: center;
  }

  .header,
  .section {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
  }

  .game-link {
    background-color: #000;
    padding: 40px;
    width: 100vw;
    height: 100vh;
    text-align: center;
    color: #fff;
    h3 {
      font-weight: 400;
      margin: auto;
      padding: 60px;
      width: 45%;
      font-size: 30px;
    }

    .words-positions {
      position: absolute;
      h1 {
        margin: 120px;
        margin-top: -150px;
        top: 30px;
        font-weight: 400;
        @include respond-below($tab) {
          margin: 45px;
          margin-top: 345px;
          top: 30px;
          font-weight: 400;
        }
      }
      h3 {
        font-weight: 200;
        @include respond-below($tab) {
          margin-bottom: 256px;
          width: 100%;
        }
      }
      .open-game {
        color: #fff;
        font-weight: bold;
      }
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    width: 75%;
    padding: 40px;
  }

  .section {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-content: center;
  }

  @include content-loader;
}
