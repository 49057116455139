@import "../App/Variables.scss";
@import "../App/Mixins.scss";

.game-intro {
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
  // margin-top: -20vh;
  &.game-plan {
    background: #90855b;
    // background-image: radial-gradient(50% 50%, #d5f2ff 50%, #9eb7cb 100%);
  }
  .preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    img {
      width: 90%;
      max-width: 800px;
      height: 100vh;
      object-fit: contain;
      margin: auto;
      display: block;
    }
  }
  .content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    text-align: center;
    > div {
      padding: 60px 0;
      @include respond-below($tab) {
        margin: 50px 0;
      }
    }
    .title {
      img {
        @include respond-below($tab) {
          width: 70%;
          max-width: 680px;
          height: auto;
          object-fit: contain;
          margin: auto;
          display: block;
        }
      }
    }
    .sub-title {
      text-transform: uppercase;
      margin-top: 15px;
      color: #fff;
    }
    .description {
      font-family: $canela;
      font-size: 46px;
      font-weight: 200;
      color: #d9c88a;
      @include respond-below($tab) {
        font-size: 30px;
      }
    }

    a {
      text-decoration: none;
      margin-top: 15px;
      display: inline-block;
      &.btn {
        @include btn();
        font-size: 30px;
        @include respond-below($tab) {
          font-size: 20px;
          margin-bottom: 30px;
        }
      }
      &.controller {
        width: 160px;
        animation: float 2.5s ease-in-out 0s infinite;
        img {
          width: 100%;
        }
      }
    }

    .link-wrap {
      position: relative;
      margin-top: 30px;
      .link-prompt {
        position: absolute;
        right: calc(50% + 80px + 30px);
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 30px;
        text-transform: uppercase;
        img {
          margin-left: 15px;
        }
      }
    }
  }
  .article-prompt {
    position: absolute;
    right: 30px;
    bottom: 30px;
    z-index: 10;
    img {
      height: 12px;
      width: auto;
      margin-left: 10px;
    }
    a {
      color: #000;
      text-decoration: none;
    }
    @include respond-below($tab) {
      font-size: 13px;
      opacity: 0.7;
      img {
        height: 9px;
        margin-left: 7px;
      }
    }
  }
}

@keyframes float {
  from {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-7px);
  }
  75% {
    transform: translateY(7px);
  }
  to {
    transform: translateY(0);
  }
}
