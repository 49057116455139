@import "../App/Variables.scss";

// .app.gameFace {
//   overflow: hidden;
// }

.twitch-block {
  display: none;
  width: 100%;
  background-color: #e6e6df;
  padding: 80px 0;
  margin: 80px 0;
  overflow: hidden;
  h2 {
    text-align: center;
    font-size: 50px;
    font-family: $canela;
    font-weight: 200;
  }
}
.menu-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  font-size: 0;
  cursor: pointer;
  background-image: url("../../assets/close-large.svg");
  width: 10%;
  height: 10%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
}
