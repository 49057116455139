@import "../App/Variables.scss";
@import "../App/Mixins.scss";
@import "../App/Base.scss";

.game-face {
  .face-slider {
    background-color: #ed1e79;
  }
  .article-wrap {
    .left {
      background-color: $gfArticleBg;
      .title {
        background: $gfArticleBg;
      }
      .read-duration {
        color: #826a6a;
      }
    }
    .quotes {
      border-left-color: rgb(201, 102, 102);
      .quote-text {
        color: rgb(201, 102, 102);
      }
    }
  }

  .twitch-link {
    display: none;
  }
}
