@import "../App/Variables.scss";
@import "../App/Mixins.scss";

.nav-bar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 30px 20px;
  pointer-events: none;
  @include respond-below($tab) {
    padding: 15px 15px 10px;
  }

  #menu-toggle {
    pointer-events: all;
    svg {
      display: block;
      @include respond-below($tab) {
        width: 32px;
        height: 28px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
  .menu {
    cursor: pointer;
  }
  #logo {
    cursor: pointer;
    pointer-events: all;
    #gamechange-logo {
      transition: all 1.3s 0.3s cubic-bezier(0.14, 1, 0.34, 1);
      transform: translateY(0);
      @include respond-below($tab) {
        width: 200px;
      }
    }
  }

  .characters {
    width: 50px;
    @include respond-below($tab) {
      width: 32px;
    }
  }
  #share-wrap {
    pointer-events: all;
    position: relative;
    #share {
      opacity: 0.4;
      cursor: pointer;
      transition: all 0.4s cubic-bezier(0.14, 1, 0.34, 1);
      &:hover {
        opacity: 0.8;
      }

      &.open {
        opacity: 0.8;
      }
    }
    #share-overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 602;
      background-color: #00000066;
      opacity: 0;
      pointer-events: none;
      &.open {
        opacity: 1;
        pointer-events: all;
      }
      .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #000000;
        color: #fff;
        padding: 25px 25px 45px;
        max-width: 300px;
        width: 100%;
        .top {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          h2 {
            // margin-top: 0;
          }
          .close {
            text-transform: uppercase;
            font-size: 12px;
            cursor: pointer;
          }
        }
        .widgets {
          display: flex;
          flex-direction: row;
          > div {
            cursor: pointer;
            opacity: 0.5;
            transition: all 0.4s cubic-bezier(0.14, 1, 0.34, 1);
            &:hover {
              opacity: 0.8;
            }
          }
          svg {
            height: 25px;
            margin-right: 15px;
            @include respond-below($tab) {
              margin: 0 0 15px;
            }
          }
        }
      }
    }
    #widgets {
      opacity: 0;
      transform: translate(100%, 0);
      transition: all 0.4s cubic-bezier(0.14, 1, 0.34, 1);
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 0;
      right: 45px;
      pointer-events: none;
      padding-top: 5px;
      @include respond-below($tab) {
        transform: translate(0, -100%);
        flex-direction: column;
        right: 0;
        top: 45px;
        align-items: center;
      }
      > div {
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.4s cubic-bezier(0.14, 1, 0.34, 1);
        &:hover {
          opacity: 0.8;
        }
        @include respond-below($tab) {
          opacity: 1;
          &:hover {
            opacity: 1;
          }
        }
      }
      &.open {
        transform: translate(0, 0);
        opacity: 1;
        pointer-events: all;
      }
      svg {
        height: 25px;
        margin-right: 15px;
        @include respond-below($tab) {
          margin: 0 0 15px;
        }
      }
    }
  }
  &.bg-black {
    // background-color: #000000bb;
    @include gradient-black();
    transition: all 0.4s ease-out;
  }
  &.dark {
    // background: #ffffffbb;
    @include gradient-white();
    transition: all 0.4s ease-out;
    #gamechange-logo {
      path {
      }
    }
    .active-story {
      color: #000;
    }
    #burger {
      stroke: #000;
    }
    #share-wrap {
      #share-icon {
        fill: #000;
      }
      #widgets {
        #icon_fb,
        #icon_twitter {
          fill: #000;
        }
      }
    }
  }

  &.landing-nav {
    #logo {
      #gamechange-logo {
        opacity: 0;
        transform: translateY(-150px);
        transition: opacity 0.9s cubic-bezier(0.14, 1, 0.34, 1);
      }
    }
    #share-wrap {
      margin-right: calc(80px + 2%);
    }
    @include respond-below($tab) {
      justify-content: flex-start;
      padding: 30px 15px 10px;
      .toggle-wrap {
        order: 1;
        margin-top: 5px;
      }
      #logo {
        order: 3;
      }
      #share-wrap {
        display: none;
        margin-right: 0;
        margin-left: 20px;
        order: 2;
      }
    }
  }
  &.gf-game {
    #logo {
      #gamechange-logo {
        opacity: 0;
        transform: translateY(-150px);
        transition: opacity 0.9s cubic-bezier(0.14, 1, 0.34, 1);
      }
    }
    #share-wrap {
      display: none;
    }
  }
}
