@import "../App/Variables.scss";

.video-page {
  &__title {
    margin: 0;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 18px;
    position: fixed;
    top: 30px;
    left: 49%;
  }
  #listVideo {
    overflow-x: auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .section-list-video {
    display: flex;
    flex-wrap: nowrap;
    .item-first,
    .item-last {
      flex: 0 0 20vw;
      max-width: 20vw;
    }
    .video-item {
      max-width: 50vw;
      flex: 0 0 50vw;
      padding-left: 40px;
      padding-right: 40px;
      &__inner {
        h6 {
          margin-top: 15px;
          margin-bottom: 5px;
          color: #ffffff;
          text-transform: uppercase;
          font-size: 18px;
        }
        h3 {
          font-family: $canela;
          color: #ffffff;
          font-weight: normal;
          font-size: 35px;
          line-height: 1.3;
          margin: 20px 0 0;
        }
      }
      &__sequence-number {
        color: #ffffff;
        margin-bottom: 15px;
        font-size: 20px;
        opacity: 0.7;
      }
    }
  }

  .video-scroll-more {
    padding: 40px 40px 0;
    &__wrapper {
      color: #ffffff;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      .scroll-bar {
        width: 150px;
        height: 2px;
        background-color: #ffffff;
        margin-left: 15px;
        margin-right: 15px;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          height: 2px;
          width: 15px;
          position: absolute;
          left: 100%;
          top: 0;
          background-color: #ffffff;
        }
        &__btn {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #ffffff;
          position: absolute;
          top: -7px;
          left: 0;
        }
      }
      input[type="range"] {
        -webkit-appearance: none;
        width: 200px;
        margin-left: 10px;
        &:focus {
          outline: none;
        }
        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 2px;
          cursor: pointer;
          background: #ffffff;
          border-radius: 1.3px;
        }
        &::-webkit-slider-thumb {
          border: 1px solid #000000;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background: #ffffff;
          cursor: pointer;
          -webkit-appearance: none;
          margin-top: -5px;
        }
        &:focus::-webkit-slider-runnable-track {
          background: #ffffff;
        }
        &::-moz-range-track {
          width: 100%;
          height: 2px;
          cursor: pointer;
          background: #ffffff;
          border-radius: 1.3px;
          border: 0.2px solid #010101;
        }
        &::-moz-range-thumb {
          border: 1px solid #000000;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background: #ffffff;
          cursor: pointer;
        }
        &::-ms-track {
          width: 100%;
          height: 2px;
          cursor: pointer;
          background: transparent;
          border-color: transparent;
          border-width: 16px 0;
          color: transparent;
        }
        &::-ms-fill-lower {
          background: #ffffff;
          border: 0.2px solid #010101;
          border-radius: 2.6px;
        }
        &::-ms-fill-upper {
          background: #ffffff;
          border: 0.2px solid #010101;
          border-radius: 2.6px;
        }
        &::-ms-thumb {
          border: 1px solid #000000;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background: #ffffff;
          cursor: pointer;
        }
        &:focus::-ms-fill-lower {
          background: #ffffff;
        }
        &:focus::-ms-fill-upper {
          background: #ffffff;
        }
      }
    }
  }
}

.video-page,
.about-page {
  .video-wrap {
    margin: 0 auto;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .ytp-expand-pause-overlay {
      display: none;
    }

    video {
      max-width: 100%;
      height: 100%;
      transform: scale(1.3);
    }
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: #000000, $alpha: 0.6);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-out;
    }
    &__btn {
      background-color: rgba($color: #ffffff, $alpha: 0.9);
      width: 70px;
      height: 70px;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      background-image: url("../../assets/videos/play-red.svg");
      background-position: center;
      background-repeat: no-repeat;
    }
    &:hover {
      .video-wrap__overlay {
        background-color: rgba($color: #000000, $alpha: 0.4);
      }
      .video-wrap__btn {
        background-color: #ffffff;
      }
    }
  }
  .modal-video {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 600;
    background-color: rgba($color: #000000, $alpha: 0.8);
    display: none;
    &__wrapper-btn {
      position: fixed;
      top: 20px;
      right: 20%;
    }
    &__btn-close {
      width: 80px;
      height: 80px;
      opacity: 0.3;
      border: 0;
      z-index: 3;
      background-color: transparent;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      &:before,
      &:after {
        content: " ";
        position: absolute;
        top: 0;
        height: 80px;
        width: 2px;
        background-color: #ffffff;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    &__header {
      position: fixed;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 26px;
      font-weight: normal;
    }
    &__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    &__content-video {
      max-width: 60%;
      margin: 0 auto;
      position: relative;
      video {
        width: 100%;
        height: auto;
      }
    }
    &__control {
      position: absolute;
      left: 0;
      bottom: 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      .control-wrapper {
        &__btn {
          width: 20px;
          height: 20px;
          border: 0;
          background-color: transparent;
          background-position: center;
          background-repeat: no-repeat;
          padding: 0;
          cursor: pointer;
          &--pause {
            background-image: url("../../assets/videos/pause.svg");
          }
          &--play {
            background-image: url("../../assets/videos/play.svg");
          }
          &--muted {
            width: 40px;
            background-image: url("../../assets/videos/mute.svg");
          }
          &--mute {
            width: 40px;
            background-image: url("../../assets/videos/muted.svg");
            background-position: right;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .range-time {
        width: calc(100% - 100px);
        height: 20px;
        margin: 0 20px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: rgba($color: #ffffff, $alpha: 0.6);
          z-index: 2;
        }
        &__btn {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ffffff;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: all 0.3s;
          position: absolute;
        }
        &:hover {
          cursor: pointer;
          &::before {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}

.about-page {
  .video-wrap {
    &__overlay {
      background-color: rgba($color: $gold, $alpha: 0.6);
    }
    &:hover {
      .video-wrap__overlay {
        background-color: rgba($color: $gold, $alpha: 0.4);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .video-page {
    width: 100%;
    height: 100vh;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: hidden;
  }
}
@media screen and (max-width: 1199px) {
  .video-page,
  .about-page {
    .video-wrap {
      height: 300px;
    }
    .modal-video {
      &__header {
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .video-page {
    .section-list-video {
      .item-first,
      .item-last {
        flex: 0 0 10vw;
        max-width: 10vw;
      }
      .video-item {
        padding-left: 15px;
        padding-right: 15px;
        &__inner {
          h6 {
            font-size: 14px;
          }
          h3 {
            font-size: 24px;
          }
        }
      }
    }
    .video-wrap {
      &__btn {
        width: 50px;
        height: 50px;
      }
    }
    .video-scroll-more {
      padding: 40px 15px 0;
    }
  }
  .about-page,
  .video-page {
    .video-wrap {
      &__btn {
        width: 50px;
        height: 50px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .video-page {
    .section-list-video {
      flex-wrap: wrap;
      .item-first,
      .item-last {
        display: none;
      }
      .video-item {
        max-width: 70vw;
        flex: 0 0 70vw;
        margin: 0 auto 60px;
        &__sequence-number {
          margin-bottom: 0;
        }
      }
    }
    .video-scroll-more {
      display: none;
    }
    &__content {
      padding-top: 110px;
    }
  }
  .about-page,
  .video-page {
    .modal-video {
      &__wrapper-btn {
        position: fixed;
        top: 20px;
        right: 10%;
      }
      &__btn-close {
        width: 40px;
        height: 40px;
        &:before,
        &:after {
          height: 40px;
        }
      }
      &__content-video {
        max-width: 80%;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .video-page {
    .section-list-video {
      .video-item {
        max-width: 90vw;
        flex: 0 0 90vw;
        &__inner {
          h6 {
            font-size: 12px;
          }
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
