@font-face {
  font-family: "Canela";
  src: url("./../../assets/fonts/Canela-Medium.otf") format("opentype");
  // src: url('./../assets/fonts/NoeDisplay/NoeDisplayBold.eot?#iefix') format('embedded-opentype'),
  //     url('./../assets/fonts/NoeDisplay/NoeDisplayBold.woff') format('woff'),
  //     url('./../assets/fonts/NoeDisplay/NoeDisplay-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela";
  src: url("./../../assets/fonts/Canela-Regular.otf") format("opentype");
  // src: url('./../assets/fonts/NoeDisplay/NoeDisplayBold.eot?#iefix') format('embedded-opentype'),
  //     url('./../assets/fonts/NoeDisplay/NoeDisplayBold.woff') format('woff'),
  //     url('./../assets/fonts/NoeDisplay/NoeDisplay-Bold.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Canela";
  src: url("./../../assets/fonts/Canela-Thin.otf") format("opentype");
  // src: url('./../assets/fonts/NoeDisplay/NoeDisplayBold.eot?#iefix') format('embedded-opentype'),
  //     url('./../assets/fonts/NoeDisplay/NoeDisplayBold.woff') format('woff'),
  //     url('./../assets/fonts/NoeDisplay/NoeDisplay-Bold.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Dia";
  src: url("./../../assets/fonts/DIA/dia-light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Dia";
  src: url("./../../assets/fonts/DIA/dia-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Dia";
  src: url("./../../assets/fonts/DIA/dia-bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "TradeGothicLT-Extended";
  src: url("./../../assets/fonts/Trade-Gothic-Extended-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "TradeGothicLT-BoldExtended";
  src: url("./../../assets/fonts/Trade-Gothic-Extended-Bold.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
