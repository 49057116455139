.play-pause {
  &.play {
    #play-wrap {
      opacity: 1;
    }
    #pause-wrap {
      opacity: 0;
    }
  }
  &.pause {
    #play-wrap {
      opacity: 0;
    }
    #pause-wrap {
      opacity: 1;
    }
  }
}

.mute {
  #speaker,
  #mute-x {
    opacity: 0.7;
  }
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &.mute-off {
    #mute-x {
      opacity: 1;
    }
    #mute-x-dark {
      opacity: 1;
    }
  }
  &.mute-on {
    #mute-x {
      opacity: 0;
    }
    #mute-x-dark {
      opacity: 0;
    }
  }
}
