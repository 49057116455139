@import "../App/Variables.scss";
@import "../App/Mixins.scss";

.scene {
  position: relative;
  width: 100%;
  // height: 100vh;
  overflow: hidden;
  background-color: #fff;

  .img-wrap {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    transition: 0.08s ease-out;
    background-image: radial-gradient(50% 50%, #d5f2ff 50%, #9eb7cb 100%);
    img {
      transition: all 0.5s;
      visibility: hidden;
      &.zoom-out {
        transform: scale(0.8);
        filter: blur(30px);
        opacity: 0;
        transition: all 0.5s;
      }
      &.large {
        transform: scale(1.4);
        filter: blur(30px);
        opacity: 0;
        transition: all 0s;
      }
      &.zoom-in {
        transform: scale(1);
        filter: blur(0px);
        opacity: 1;
        transition: all 0.3s;
      }
    }
  }
  .nav-bar {
    background-color: #666;
  }

  // img {
  //   width: 100%;
  //   height: 100vh;
  //   object-fit: contain;
  //   background-color: #d0e8ff;
  // }

  .overlay-interactive {
    position: absolute;
    bottom: 500px;
    left: 550px;
    right: 550px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 30px;
    color: #ffffff;
    text-align: center;
  }

  .overlay {
    position: absolute;
    bottom: 30px;
    width: 50%;
    left: 25%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 40px;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    transition: all 0.5s;

    @include respond-below($tab) {
      bottom: 30px;
      width: 90%;
      left: 5%;
      padding: 20px;
    }
    &.puzzle {
      // bottom: 5%;
      // right: 5%;
      // left: unset;
      // width: auto;
      // padding: 10px;
      // .message {
      //   display: none;
      // }
    }

    .counter {
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      @include respond-below($tab) {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        margin-bottom: 10px;
        margin-top: -4px;
      }
    }

    .buttons {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      a {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px 20px;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        margin: 0 15px;
        display: flex;
        align-items: center;
        &.secondary {
          background-color: rgba(0, 0, 0, 0.4);
          color: rgba(255, 255, 255, 0.7);
        }
      }
      .transition-btn {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px 20px;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        margin: 0 15px;
        cursor: pointer;
        display: inline;
      }
      .sharing {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px 20px;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        margin: 0 15px;
        display: inline;
        .label {
          font-size: 14px;
        }
        .shares {
          display: flex;
          flex-direction: row;
          div {
            margin: 0 5px;
            cursor: pointer;
          }
        }
      }
      @include respond-below($tab) {
        justify-content: space-around;
        a,
        .transition-btn {
          margin: 0;
          padding: 10px 15px;
        }
        a {
          max-width: 45%;
          &:first-child {
            &:last-child {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .top-controls {
    color: rgb(126, 122, 122);
    text-decoration: none;
    font-weight: bold;
    position: absolute;
    top: 15px;
    left: 80px;
    a {
      color: rgba(0, 0, 0, 0.6);
      text-decoration: none;
      font-weight: 200;
      text-transform: uppercase;
      font-size: 11px;
    }
  }
  .box-level-up {
    &--item {
      position: absolute;
      // color: #ffffff;
      border: 1px solid #000000;
      width: 160px;
      text-align: center;
      padding: 15px;
      cursor: pointer;
      // transition: all 0.5s;
      animation: animateBox 0.9s;
      &:nth-child(2) {
        top: 20%;
        right: 60%;
      }
      &:nth-child(3) {
        top: 20%;
        left: 60%;
      }
      &:nth-child(4) {
        top: 40%;
        right: 60%;
      }
      &:nth-child(5) {
        top: 40%;
        left: 60%;
      }
    }
  }
  .box-target {
    position: fixed;
    background-color: transparent;
    top: 0;
    height: 70vh;
    left: calc(50% - 90px);
    width: 180px;
  }
  @keyframes animateBox {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
}
@media screen and (max-width: 991px) {
  .scene {
    .box-target {
      width: 100px;
      left: calc(50% - 50px);
    }
    .box-level-up {
      &--item {
        font-size: 14px;
        width: 140px;
        padding: 10px;
        &:nth-child(2) {
          right: 70%;
        }
        &:nth-child(3) {
          left: 70%;
        }
        &:nth-child(4) {
          right: 70%;
        }
        &:nth-child(5) {
          left: 70%;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .scene {
    .box-level-up {
      &--item {
        font-size: 12px;
        width: 95px;
      }
    }
  }
}
