@import "../App/Variables.scss";
@import "../App/Mixins.scss";
@import "../App/Base.scss";

.article-wrap-split {
  display: flex;
  flex-direction: row;
  width: 100%;

  .left {
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 40%;
    overflow: hidden;
    position: relative;

    .title {
      width: 100%;
      height: 130vh;
      position: absolute;
      top: 0;
      z-index: 6;
      padding: 30px 30px 30px 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      h2 {
        font-family: $canela;
        font-size: 78px;
        font-weight: normal;
        color: #000000;
        letter-spacing: -0.64px;
        line-height: 84px;
        margin-bottom: 20px;
        margin-top: 40vh;
      }
      .read-duration {
        font-size: 20px;
        color: #826a6a;
        letter-spacing: -0.1px;
        line-height: 31px;
      }
    }
    .videos {
      position: absolute;
      top: 100vh;
    }

    &.fixed {
      // position: fixed;
      .video-wrap {
        width: 40%;
        position: fixed;
      }
    }
    video {
      opacity: 0;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: left;
      transition: all 0.6s ease-out;
      pointer-events: none;
      filter: blur(10px) grayscale(100%);
      transform: scale(1.2);
      position: relative;
    }
    .video-wrap {
      height: 100vh;
      position: absolute;
      top: 0;
      overflow: hidden;
      &.current {
        video {
          opacity: 0.2;
        }
      }
      &.active {
        z-index: 5;
        video {
          opacity: 0.6;
          transition: all 0.6s ease-out;
          filter: blur(0) grayscale(100%);
          transform: scale(1);
        }
      }
    }
    &.active {
      .video-wrap {
        opacity: 1;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 60%;
    padding-top: 40vh;
  }

  .quote-wrap {
    margin: 130px auto;
    width: 100%;
  }

  .quotes {
    width: 80%;
    height: auto;
    margin-left: 10%;
    padding-left: 5%;
    font-family: Canela;
    background-color: #fff;
    border-left: solid;
    border-width: thin;
    border-left-color: rgb(201, 102, 102);
    video {
      width: 100%;
      height: auto;
    }

    .quote-text {
      color: rgb(201, 102, 102);
      font-size: 30px;
      -webkit-font-smoothing: auto;
      background-blend-mode: color;
      line-height: 1.4em;
      @include respond-below($tab) {
        font-size: 20px;
      }
    }
  }

  .block-container {
    display: flex;
    flex-direction: row;
    padding: 0px 15%;
    width: 120%;
    justify-content: space-between;
    @include respond-below($tab) {
      flex-direction: column;
      width: 90%;
      padding: 0;
      justify-content: space-between;
    }
    &.intro {
      .text-block {
        font-size: 24px;
      }
    }

    .text-block {
      width: 65%;
      font-size: 18px;
      line-height: 1.3em;
      blockquote {
        p {
          font-weight: 200;
          font-size: 24px;
        }
      }
      @include respond-below($tab) {
        width: 90%;
        font-size: 16px;
      }
    }
  }
}

.next-article {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: row;
  background-color: #000;
  padding-left: 5%;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 10;

  a {
    // width: 50%;
    color: #fff;
    text-decoration: none;
  }
}
