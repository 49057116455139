@import "../App/Variables.scss";
@import "../App/Mixins.scss";

.game-tease {
  text-decoration: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: top right;
  right: 5%;
  width: 200px;
  color: #fff;
  z-index: 601;
  transition: all 0.3s ease-in-out;
  @include respond-below($tab) {
    position: relative;
    top: unset;
    right: unset;
    transform: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 40px;
    padding: 10px 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 5%;
      z-index: -1;
    }
  }

  .video-wrap {
    border-radius: 99px;
    width: 200px;
    height: 200px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
    @include respond-below($tab) {
      width: 120px;
      height: 120px;
      border-radius: 59px;
      margin-left: 10px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cta {
    line-height: 1.4em;
    font-family: $displaySans;
    width: 90%;
    margin: 10px auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0.7;
    transition: opacity 0.3s ease-in;
    @include respond-below($tab) {
      width: auto;
      flex: 1;
      margin: 0 15px;
      line-height: 1.1em;
      opacity: 1;
    }
    .text-wrap {
      display: flex;
      flex-direction: column;
      .label {
        display: none;
        text-transform: uppercase;
        font-size: 10px;
        opacity: 0.35;
        @include respond-below($tab) {
          display: block;
        }
      }
      .title {
        display: none;
        font-size: 26px;
        font-weight: bold;
        margin: 1px 0 5px;
        line-height: 1.1em;
        @include respond-below($tab) {
          display: block;
        }
      }
      .text {
        @include respond-below($tab) {
          font-size: 13px;
        }
      }
    }
    .arrow {
      // background-image: url("../../assets/arrow-cta.svg");
      svg {
        width: 39px;
        height: 26px;
        path,
        polyline {
          transition: all 0.3s ease-in;
        }
      }
    }
  }
  &:hover {
    .cta {
      opacity: 1;
    }
  }
  &.end-prompt {
    @include respond-above($tab) {
      color: #333;
      z-index: 20;
      .arrow {
        path,
        polyline {
          stroke: #333;
          transition: all 0.3s ease-in;
        }
      }
    }
  }
  &.gamePlan {
    @include respond-below($tab) {
      .title {
        color: #287ff5;
      }
      &:after {
        background-color: #323347;
      }
    }
  }
  &.gameFace {
    @include respond-below($tab) {
      .title {
        color: #ef1a7b;
      }
      &:after {
        background-color: #473232;
      }
    }
  }
  &.scrolled {
    top: 30px;
    transform: translateY(0) scale(0.5);
    color: #999;
    video {
      filter: grayscale(1);
    }
    .arrow {
      path,
      polyline {
        stroke: #999;
        transition: all 0.3s ease-in;
      }
    }
    &:hover {
      color: #9c5353;
      transform: translateY(0) scale(1);
      video {
        filter: grayscale(0);
      }
      .arrow {
        path,
        polyline {
          stroke: #9c5353;
          transition: all 0.3s ease-in;
        }
      }
    }
    @include respond-below($tab) {
      top: unset;
      transform: none;
      color: #fff;
      video {
        filter: none;
      }
    }
    &.gameFace {
      &:hover {
        color: #9c5353;
        .arrow {
          path,
          polyline {
            stroke: #9c5353;
            transition: all 0.3s ease-in;
          }
        }
      }
    }
    &.gamePlan {
      &:hover {
        color: $gold;
        .arrow {
          path,
          polyline {
            stroke: $gold;
            transition: all 0.3s ease-in;
          }
        }
      }
    }
  }
}

.game-prompt-spacer {
  .game-tease {
    display: none;
    @include respond-below($tab) {
      display: flex;
    }
  }
}
