@import "../App/Variables.scss";

// Responsive Breakpoints.
$mob: 500;
$tab: 800;
$desk: 1040;
$wide: 1200;
$landscape: 400;

// Responsive Mixins.
@mixin respond-below($breakpoint) {
  @media only screen and (max-width: #{$breakpoint}px) {
    @content;
  }
}
@mixin respond-above($breakpoint) {
  @media only screen and (min-width: #{$breakpoint}px) {
    @content;
  }
}
@mixin respond-btwn($bp1, $bp2) {
  @media only screen and (min-width: #{$bp1}px) and (max-width: #{$bp2}px) {
    @content;
  }
}

// Height Mixins.
@mixin respond-below-height($breakpoint) {
  @media only screen and (max-height: #{$breakpoint}px) {
    @content;
  }
}

@mixin content-loader {
  .error {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
  }
}

@mixin btn() {
  text-transform: uppercase;
  border: 2px #fff solid;
  border-bottom: 6px #fff solid;
  padding: 20px 26px;
  color: #fff;
  font-size: 18px;
  @include respond-below($tab) {
    font-size: 16px;
    padding: 15px 20px;
  }
}

@mixin gradient-black() {
  background: linear-gradient(
    to bottom,
    hsl(0, 0%, 0%) 0%,
    hsla(0, 0%, 0%, 0.987) 12.4%,
    hsla(0, 0%, 0%, 0.951) 23.6%,
    hsla(0, 0%, 0%, 0.896) 33.6%,
    hsla(0, 0%, 0%, 0.825) 42.7%,
    hsla(0, 0%, 0%, 0.741) 50.8%,
    hsla(0, 0%, 0%, 0.648) 58.1%,
    hsla(0, 0%, 0%, 0.55) 64.6%,
    hsla(0, 0%, 0%, 0.45) 70.5%,
    hsla(0, 0%, 0%, 0.352) 75.7%,
    hsla(0, 0%, 0%, 0.259) 80.5%,
    hsla(0, 0%, 0%, 0.175) 84.9%,
    hsla(0, 0%, 0%, 0.104) 88.9%,
    hsla(0, 0%, 0%, 0.049) 92.7%,
    hsla(0, 0%, 0%, 0.013) 96.4%,
    hsla(0, 0%, 0%, 0) 100%
  );
}

@mixin gradient-white() {
  background: linear-gradient(
    to bottom,
    hsl(0, 0%, 100%) 0%,
    hsla(0, 0%, 100%, 0.987) 12.4%,
    hsla(0, 0%, 100%, 0.951) 23.6%,
    hsla(0, 0%, 100%, 0.896) 33.6%,
    hsla(0, 0%, 100%, 0.825) 42.7%,
    hsla(0, 0%, 100%, 0.741) 50.8%,
    hsla(0, 0%, 100%, 0.648) 58.1%,
    hsla(0, 0%, 100%, 0.55) 64.6%,
    hsla(0, 0%, 100%, 0.45) 70.5%,
    hsla(0, 0%, 100%, 0.352) 75.7%,
    hsla(0, 0%, 100%, 0.259) 80.5%,
    hsla(0, 0%, 100%, 0.175) 84.9%,
    hsla(0, 0%, 100%, 0.104) 88.9%,
    hsla(0, 0%, 100%, 0.049) 92.7%,
    hsla(0, 0%, 100%, 0.013) 96.4%,
    hsla(0, 0%, 100%, 0) 100%
  );
}
