@import "../App/Variables.scss";
@import "../App/Mixins.scss";

.about-page {
  color: #ffffff;
  padding: 140px 0 150px;
  transition: all 0.6s 0s ease-out;
  @include respond-below($tab) {
    padding-top: 100px;
  }

  &.exit {
    opacity: 0;
    transform: translateY(-100px);
  }
  .mute-toggle {
    position: fixed;
    left: 2%;
    bottom: 2%;
    z-index: 50;
    opacity: 0.6;
  }
  .about-page-title {
    margin: 0;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
  }
  .inner-center {
    width: 80%;
    max-width: 800px;
    margin: auto;
  }
  h1 {
    font-family: $canela;
    font-weight: 200;
    font-size: 46px;
    letter-spacing: -0.75px;
    margin: 40px 0 70px;
  }
  .content {
    width: 70%;
    @include respond-below($tab) {
      width: 100%;
    }
  }
  h2,
  h3 {
    font-family: $canela;
    font-weight: 200;
    font-size: 36px;
    color: #a67f4c;
    letter-spacing: -0.59px;
    margin: 80px 0 30px;
  }
  .video-wrap {
    margin-bottom: 100px;
  }

  .credits {
    .credit {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 30px 0;
      .role {
        width: 47.5%;
        font-weight: bold;
      }
      .name {
        width: 47.5%;
        font-weight: 200;
      }
      @include respond-below($tab) {
        flex-direction: column;
        .role {
          width: 100%;
        }
        .name {
          width: 100%;
        }
      }
    }
  }
  .accred {
    font-weight: bold;
    font-size: 22px;
    margin: 100px 0 0;
  }
  .joint-fund-logo {
    margin: 30px 0 100px;
    img {
      width: 100%;
      max-width: 300px;
    }
  }
}
