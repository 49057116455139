@import "./Fonts.scss";
@import "./Variables.scss";
@import "./Mixins.scss";

html {
  // overscroll-behavior: none;
  background: #000;
}

body {
  margin: 0;
  padding: 0;
  font-family: $sans;
  &.no-bounce {
    overscroll-behavior: none;
  }
  &.noscroll {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

video {
  outline: none;
}
* {
  box-sizing: border-box;
}

.not-found {
  @include content-loader();
}
